import Api from "@/services/Api"; // eslint-disable-line

export const notifications = {
    namespaced: true,
    state: {
        data: null,
        brand_id: null,
        changed:false,
    },
    mutations: {
        setData(state, object)
        {
            state.data     = object.data;
            state.brand_id = object.brand_id;
        },
        markAsChanged(state, object)
        {
            if(object.id && object.data && state.data[object.id] && object.data)
            {
                state.data[object.id].action_status = object.data.action_status;
                state.data[object.id].read_status = object.data.read_status;
            }
            state.changed = new Date().getTime()
        },
        markAsRead(state, id)
        {
            if(state.data[id])
                state.data[id].read_status = 'read';
            state.changed = new Date().getTime()
        },
        markAsUnread(state, id)
        {
            if(state.data[id])
                state.data[id].read_status = 'unread';
            state.changed = new Date().getTime()
        }
    },
    getters: {
        getChangedTime(state)
        {
            return state.changed;
        },
        getCurrentData(state)
        {
            return state.data;
        },
        getCurrentDataBrandId(state)
        {
            return state.brand_id;
        }
    },
    actions: {
        clearNotifications({commit})
        {
           commit("setData", {data: false, brand_id: false});
        },
        refreshNotifications({commit, state}, brand_id)
        {
            return new Promise( (success, failure) =>
                               {
                                   Api.workspaceGet("brands/" + brand_id + "/notifications")
                                            .then(
                                                    response =>
                                                    {
                                                        if(!state.brand_id || brand_id == state.brand_id)
                                                        commit("setData", {data: response.data, brand_id: brand_id});
                                                        success(response.data);
                                                    },
                                                    err =>
                                                    {
                                                        console.log(err);
                                                        failure(false);
                                                    }
                                            );
                               });
        },
        getNotifications({commit, state, dispatch}, brand_id)
        {
            if(brand_id == state.brand_id)
                return state.data;
            commit("setData", {data: false, brand_id: false});
            dispatch("refreshNotifications", brand_id);

        }
    }
}