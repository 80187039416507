import {createStore} from 'vuex'
import {auth} from './modules/auth'
import {report} from './modules/report'
import {notifications} from './modules/notifications'
import Api from "@/services/Api";

export default createStore({
    state: {
        workspace:null,
        currentBrand:false,
        brands: [],
        darkMode:false,
        outdated:false
    },
    mutations: {
        setWorkspace(state, workspace)
        {
          state.workspace = workspace;
        },
        setCurrentBrandConnections(state, connections)
        {
          state.currentBrand.connections = connections;
        },
      setCurrentBrandId(state, id)
      {
          for(var b in state.brands)
          {
              if(state.brands[b].id == id)
              {
                  state.currentBrand = state.brands[b];
                  localStorage.setItem("brand", id)
                  break;
              }
          }
      },
        setBrands(state, brands)
        {
            state.brands = brands;

        },
      setDarkMode(state, enabled)
      {
          state.darkMode = enabled;
      },
     setOutdated(state, value)
      {
          state.outdated = value;
      }
    },
    actions:{
        async reloadConnections({commit, state})
        {

            if(state.currentBrand)
            {
                state.currentBrand.connections = false;
                var brand_id = state.currentBrand.id;
                await Api.workspaceGet("brands/" + state.currentBrand.id + "/connections")
                   .then(response =>
                     {
                        let connections = response && response.data ? response.data : [];
                        if(state.currentBrand.id == brand_id)
                         commit('setCurrentBrandConnections', connections);
                     }, err =>
                     {
                         console.log(err);
                     });
            }
        },
        reloadBrands({commit, state}){
            return new Promise((success, failure) => {
                Api.workspaceGet("/brands")
                  .then(response => {
                    let brands = Object.assign({}, response.data);
                    commit("setBrands", brands);
                    if(brands[0])
                    {
                        var currentId = state.currentBrand && state.currentBrand.id ? state.currentBrand.id : false;
                        if(currentId === false)
                        {
                            var historical = localStorage.getItem("brand");
                            if(historical)
                                currentId = historical;
                        }
                        state.currentBrand = false;
                        if(currentId)
                            commit("setCurrentBrandId", currentId);
                        if(state.currentBrand === false)
                            commit("setCurrentBrandId", brands[0].id);
                    }
                    success(brands)
                  },(err) => { failure(err) });
            });
        }
    },
    getters: {
        getWorkspace(state)
        {
          return state.workspace;
        },
        getBrands(state) {
            return state.brands;
        },
        getCurrentBrand(state) {
            return state.currentBrand;
        },
        getWorkspaceUser(state)
        {
            return state.workspace && state.workspace.user ? state.workspace.user : false;
        },
        isDarkMode(state) {
            return state.darkMode;
        },
        isOutdated(state)
        {
          return state.outdated;
        }
    },
    modules: {
        auth,
        report,
        notifications
    }
})
